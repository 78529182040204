/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    h3: "h3",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "We implement HATEOAS throughout our API to help you:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#view-additional-information-about-a-resource"
  }, "View additional information about a resource.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#navigate-a-list-of-records-using-pagination"
  }, "Navigate a list of records using pagination.")), "\n"), "\n", React.createElement(_components.h2, null, "View additional information about a resource"), "\n", React.createElement(_components.p, null, "If a resource has a relationship with other resources in our API, we provide HATEOAS links to the other resources in our response."), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.p, null, "If you retrieve the details of a payment, we include a ", React.createElement(_components.code, null, "links"), " object that contains links to any referenced refunds or reversals that are related to the payment."), "\n", React.createElement(_components.p, null, "The following example response contains links to two refunds related to the payment."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "  \"links\": [\n    {\n      \"rel\": \"refund\",\n      \"method\": \"get\",\n      \"href\": \"https://api.payroc.com/v1/refunds/CD3HN88U9F\"\n    },\n    {\n      \"rel\": \"refund\",\n      \"method\": \"get\",\n      \"href\": \"https://api.payroc.com/v1/refunds/FPU8P48WN8\"\n    }\n  ]\n")), "\n", React.createElement(_components.h2, null, "Navigate a list of records using pagination"), "\n", React.createElement(_components.p, null, "If a GET request returns a large number of results, we send the results as a ", React.createElement(_components.a, {
    href: "/api/pagination"
  }, "paginated list"), ". To help you navigate through the results, we include HATEOAS links so that you can navigate through the results."), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.p, null, "The following example response contains a ", React.createElement(_components.code, null, "links"), " object with HATEOAS links to the next page and previous page of a paginated list of payments."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"links\": [\n    {\n      \"rel\": \"next\",\n      \"method\": \"get\",\n      \"href\": \"<https://api.payroc.com/v1/payments?processingTerminalId=1001&limit=2&after=CW4BA4MUH0>\"\n    },\n    {\n      \"rel\": \"previous\",\n      \"method\": \"get\",\n      \"href\": \"<https://api.payroc.com/v1/payments?processingTerminalId=1001&limit=2&before=IFA1T74OBS>\"\n    }\n  ]\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
